import {
  HStack,
  Stack,
  VStack,
  Text,
  Box,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { Pagination } from '$/components/core/Pagination';
import {
  DEFAULTFOLDERNAME,
  FavoriteFolder,
  useFavoriteStore,
} from '$/stores/useFavoriteStore';

const foldersPerPage = 3;

interface CollapsibleWrapperProps {
  children: ReactNode;
  isCollapsible?: boolean;
  isOpen?: boolean;
}

const CollapsibleWrapper: FC<CollapsibleWrapperProps> = ({
  children,
  isCollapsible,
  isOpen,
}) => {
  if (!isCollapsible) <>{children}</>;
  return <Collapse in={isOpen}>{children}</Collapse>;
};

interface FavoriteFolderListProps {
  isCollapsible?: boolean;
}

export const FavoriteFolderList: FC<FavoriteFolderListProps> = ({
  isCollapsible = false,
}) => {
  const { t } = useTranslation();

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const [currentPage, setCurrentPage] = useState(1);

  const folders = useFavoriteStore.useFavoriteFolders();
  const globalFavoriteFolders = useFavoriteStore.useGlobalFavoriteFolders();
  const favorites = useFavoriteStore.useFavorites();
  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();

  const updatePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const defaultFolder: FavoriteFolder = {
    id: DEFAULTFOLDERNAME,
    name: t('favorites.generalStorage'),
    isGlobalFavoriteFolder: false,
    hasThumbnail: false,
  };

  const folderItems = [defaultFolder, ...globalFavoriteFolders, ...folders].map(
    (folder) => {
      const itemsInFolder = favorites.filter((favorite) =>
        favorite.parentFolderIds.includes(folder.id),
      );

      return { ...folder, favoriteCount: itemsInFolder.length };
    },
  );

  const pageCount = Math.ceil(folderItems.length / foldersPerPage);
  const offset = (currentPage - 1) * foldersPerPage;
  const shownFolders = folderItems.slice(offset, offset + foldersPerPage);

  return (
    <Stack gap='0'>
      <Box>
        {!isCollapsible && (
          <AttributeHeader
            label={t('inspirationEditor.attributes.favorites.title')}
            icon={<Icon w='14px' icon='like' />}
            description={t('inspirationEditor.attributes.favorites.info')}
          />
        )}
        {isCollapsible && (
          <Box
            onKeyDown={({ key }) => key === 'Enter' && onToggle()}
            tabIndex={0}
          >
            <FilterHeader
              header={t('inspirationEditor.attributes.favorites.title')}
              isActive={isOpen}
              onClick={onToggle}
              prefix={<Icon w='14px' icon='like' />}
              isCollapsible
            />
          </Box>
        )}
      </Box>

      <CollapsibleWrapper isCollapsible={isCollapsible} isOpen={isOpen}>
        <Stack gap='2' pt='3'>
          <Stack>
            {shownFolders.map((item) => (
              <HStack
                key={item.id}
                as='button'
                py='1'
                _hover={{ bg: 'bodyBackground' }}
                onClick={() => setActiveFolderId(item.id)}
              >
                <Icon
                  icon='folder'
                  borderRadius='2px'
                  boxSize='24px'
                  p='1'
                  border='1px solid'
                  borderColor='border'
                />
                <VStack alignItems='flex-start' gap='0'>
                  <Text align='left' fontSize='sm' lineHeight='4' noOfLines={1}>
                    {item.name}
                  </Text>
                  <Text color='textButton.color' fontSize='xs' lineHeight='4'>
                    {t('inspirationEditor.folderFavoriteCount', {
                      count: item.favoriteCount,
                    })}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </Stack>

          <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onPageChange={updatePage}
          />
        </Stack>
      </CollapsibleWrapper>
    </Stack>
  );
};
