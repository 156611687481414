import {
  HStack,
  Button,
  Stack,
  Text,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HookFormDropdownInput,
  HookFormDropdownInputProps,
} from '$/components/core/Form/HookFormDropdownInput';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { CountryBrandMenu } from '$/pages/AdminPages/pages/CountriesPage/components/AddBrandCountryModal/components/CountryBrandMenu';
import { SelectedLanguagesTags } from '$/pages/AdminPages/pages/CountriesPage/components/SelectedLanguagesTags';
import { useFilteredLanguageCodes } from '$/pages/AdminPages/pages/CountriesPage/hooks/useFilteredAvailableLanguageCodes';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  createBrandCountry,
} from '$/services/usecases/admin/brandCountries';

export type AddBrandCountryData = {
  identifier: string;
  languages: string[];
  languageInput: string;
  isBrand: boolean;
};

const Input = (props: HookFormInputProps<AddBrandCountryData>) => (
  <HookFormInput<AddBrandCountryData> {...props} />
);

const DropdownInput = (
  props: HookFormDropdownInputProps<AddBrandCountryData>,
) => <HookFormDropdownInput<AddBrandCountryData> {...props} />;

interface Props {
  onCloseModal: () => void;
}

export const AddBrandCountryForm: FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation();

  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formMethods = useForm<AddBrandCountryData>({
    defaultValues: { isBrand: false, languages: [] },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: createBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
    onSuccess: (res) => {
      if (res.isSuccessful) {
        onCloseModal();
        return;
      }

      showToast(
        t('admin.countries.addCountryBrand.generalError'),
        'error',
        cloudMessageTranslation(res.response.message),
      );
    },
  });

  const languages = formMethods.watch('languages');
  const query = formMethods.watch('languageInput');
  const { filteredLanguageCodes } = useFilteredLanguageCodes(
    t,
    query,
    languages,
  );

  useEffect(() => {
    if (query?.length > 0) onOpen();
  }, [query, onOpen]);

  const onSubmit = (data: AddBrandCountryData) => {
    mutate({
      id: data.identifier,
      isActive: true,
      isBrand: data.isBrand,
      languageIds: data.languages,
    });
  };

  const selectLanguage = (code?: string) => {
    if (!code && (!filteredLanguageCodes || !filteredLanguageCodes.length)) {
      return;
    }

    formMethods.resetField('languageInput');
    setTimeout(() => onClose(), 0);

    const newCode = code ?? filteredLanguageCodes![0];
    formMethods.setValue('languages', [
      ...(formMethods.getValues('languages') || []),
      newCode,
    ]);
  };

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' gap='6' onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack gap='6'>
          <Box pos='relative'>
            <Input
              accessor='identifier'
              label={t('admin.countries.addCountryBrand.identifierLabel')}
              placeholder={t(
                'admin.countries.addCountryBrand.identifierPlaceholder',
              )}
              registerOptions={{
                required: t(
                  'admin.countries.addCountryBrand.errorMessages.isRequired',
                ),
              }}
            />
            <CountryBrandMenu form={formMethods} />
          </Box>

          <Stack>
            <DropdownInput
              dropdownContent={() => {
                return (
                  <Stack pt='2' pb='6px'>
                    {filteredLanguageCodes?.length === 0 && (
                      <Text px='4' py='6px' color='lighterText' fontSize='sm'>
                        {t('admin.countries.addCountryBrand.noResults')}
                      </Text>
                    )}
                    {filteredLanguageCodes?.map((code) => (
                      <Button
                        key={code}
                        px='4'
                        py='6px'
                        fontSize='sm'
                        fontWeight='normal'
                        textAlign='left'
                        _hover={{ bg: 'bodyBackground' }}
                        cursor='pointer'
                        onClick={() => {
                          selectLanguage(code);
                        }}
                        variant='unstyled'
                      >
                        <Text>{t(`languages.${code}`)}</Text>
                      </Button>
                    ))}
                  </Stack>
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  selectLanguage();
                }
              }}
              dropdownOpen={isOpen}
              toggleDropdown={(open) => (open ? onOpen() : onClose())}
              accessor='languageInput'
              label={t('admin.countries.addCountryBrand.languagesLabel')}
              placeholder={t(
                'admin.countries.addCountryBrand.languagesPlaceholder',
              )}
              registerOptions={{
                validate: {
                  countryExists: () =>
                    formMethods.getValues('languages').length > 0 ||
                    t(
                      'admin.languages.addCountryBrand.errorMessages.minimumOneLanguage',
                    ),
                },
              }}
            />
            <Text color='lighterText' fontSize='xs'>
              {t(
                'admin.countries.addCountryBrand.languagesForThisBrandCountry',
              )}
            </Text>
          </Stack>

          <SelectedLanguagesTags
            languages={languages}
            onDelete={(index) =>
              formMethods.setValue(
                'languages',
                languages.filter((_, i) => i !== index),
              )
            }
          />
        </Stack>

        <HStack justifyContent='space-between' w='full' mt='4'>
          <Button
            color='lighterText'
            fontSize='sm'
            onClick={onCloseModal}
            variant='ghost'
          >
            {t('general.cancel')}
          </Button>
          <Button
            px='6'
            py='3'
            fontSize='sm'
            isLoading={isPending}
            type='submit'
            variant='primary'
          >
            {t('admin.countries.addCountryBrand.addCountryBrand')}
          </Button>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
