import { favoritesEndpoints } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { GeneralBackendResponseObjectSchema } from '$/services/mapper/authentication';
import { validateToken } from '$/services/usecases/authentication';
import {
  CreateFavoriteFolderResponseSchema,
  GetFavoritesResponseSchema,
} from '$/services/usecases/favorites/mapper/getFavoritesResponse';

export const getAllFavoritesAndFolders = async () => {
  await validateToken();
  return fetcher(favoritesEndpoints.GET_FAVORITES_URL, {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetFavoritesResponseSchema.parse(data),
  });
};

export const addFavorite = async (
  materialId: string,
  parentFolders?: string[],
) => {
  await validateToken();
  return fetcher(favoritesEndpoints.ADD_FAVORITE_URL(materialId), {
    method: 'POST',
    includeCredentials: true,
    body: {
      parentFolders,
    },
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const removeFavorite = async (materialId: string) => {
  await validateToken();
  return fetcher(favoritesEndpoints.REMOVE_FAVORITE_URL(materialId), {
    method: 'DELETE',
    includeCredentials: true,
    body: {},
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const changeFoldersOfFavorite = async (
  materialId: string,
  folderIds: string[],
) => {
  await validateToken();
  return fetcher(favoritesEndpoints.CHANGE_FAVORITE_FOLDERS_URL(materialId), {
    method: 'PATCH',
    includeCredentials: true,
    body: folderIds,
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const addFolder = async (name: string) => {
  await validateToken();
  return fetcher(favoritesEndpoints.ADD_FAVORITE_FOLDER_URL, {
    method: 'POST',
    includeCredentials: true,
    body: { name },
    parser: (data) => CreateFavoriteFolderResponseSchema.parse(data),
  });
};

export const removeFolder = async (id: string) => {
  await validateToken();
  return fetcher(favoritesEndpoints.REMOVE_FAVORITE_FOLDER_URL(id), {
    method: 'DELETE',
    includeCredentials: true,
    body: {},
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const renameFolder = async (id: string, newName: string) => {
  await validateToken();
  return fetcher(favoritesEndpoints.UPDATE_FAVORITE_FOLDER_URL(id), {
    method: 'PATCH',
    includeCredentials: true,
    body: {
      newName,
    },
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const setGlobalFavoriteFolderState = async (
  folderId: string,
  isGlobal: boolean,
  thumbnail: File | null,
) => {
  const data = new FormData();
  data.append('isGlobalFavoriteFolder', isGlobal.toString());
  if (thumbnail != null) {
    data.append('file', thumbnail);
  }
  await validateToken();
  return fetcher(
    favoritesEndpoints.CHANGE_GLOBAL_FAVORITE_FOLDER_URL(folderId),
    {
      method: 'POST',
      includeCredentials: true,
      body: data,
      parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
      isFormData: true,
    },
  );
};
