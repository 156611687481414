import {
  ZustandHookSelectors,
  createSelectorHooks,
} from 'auto-zustand-selectors-hook';
import { z } from 'zod';
import { create } from 'zustand';

import { i18n } from '$/components/core/Localization/18n';
import { logError, trackEvent } from '$/logger';
import { MaterialType } from '$/services/mapper/uses';
import {
  addFavorite,
  addFolder,
  changeFoldersOfFavorite,
  getAllFavoritesAndFolders,
  removeFavorite,
  removeFolder,
  renameFolder,
} from '$/services/usecases/favorites';
import {
  FavoriteFolderSchema,
  FavoriteSchema,
} from '$/services/usecases/favorites/mapper/getFavoritesResponse';
import { mapMaterialTypeToCategory } from '$/utils/piwikUtils';

export const DEFAULTFOLDERNAME = 'default';

export type Favorite = z.infer<typeof FavoriteSchema>;

export type FavoriteFolder = z.infer<typeof FavoriteFolderSchema>;

type State = {
  favorites: Favorite[];
  favoriteFolders: FavoriteFolder[];
  globalFavoriteFolders: FavoriteFolder[];
  activeFolderId: string | null;
  favoritesInitiated: boolean;
};

type Actions = {
  getFavorites: (skipCheck?: boolean) => Promise<{
    favorites: Favorite[];
    favoriteFolders: FavoriteFolder[];
  }>;
  addFavorite: (
    materialId: Favorite['materialId'],
    materialType?: MaterialType,
    materialLabel?: string,
    parentFolders?: Favorite['parentFolderIds'],
  ) => Promise<void>;
  removeFavorite: (favoriteId: Favorite['materialId']) => Promise<void>;
  toogleFavorite: (
    favoriteId: Favorite['materialId'],
    favoriteType?: MaterialType,
    favoriteLabel?: string,
  ) => Promise<void>;
  changeFoldersOfFavorite: (
    favoriteId: Favorite['materialId'],
    folders: Favorite['parentFolderIds'],
  ) => Promise<void>;
  removeFavoriteFromFolder: (
    favoriteId: Favorite['materialId'],
    folderId: Favorite['parentFolderIds'][number],
  ) => Promise<void>;
  addFavoriteToFolder: (
    favoriteId: Favorite['materialId'],
    folderId: Favorite['parentFolderIds'][number],
  ) => Promise<void>;
  isFavorite: (materialId: Favorite['materialId']) => boolean;
  addFolder: (
    name?: FavoriteFolder['name'],
  ) => Promise<FavoriteFolder['id'] | null>;
  removeFolder: (id: FavoriteFolder['id']) => Promise<void>;
  duplicateFolder: (
    id: FavoriteFolder['id'],
    nameSuffix: string,
  ) => Promise<void>;
  renameFolder: (
    id: FavoriteFolder['id'],
    newName: FavoriteFolder['name'],
  ) => Promise<void>;
  setActiveFolderId: (id: FavoriteFolder['id'] | null) => void;
};

const initialValues: State = {
  favorites: [],
  favoriteFolders: [],
  globalFavoriteFolders: [],
  activeFolderId: null,
  favoritesInitiated: false,
};

const store = create<State & Actions>()((set, get) => ({
  ...initialValues,
  getFavorites: async (skipCheck = false) => {
    if (!skipCheck && get().favoritesInitiated)
      return {
        favorites: get().favorites,
        favoriteFolders: get().favoriteFolders,
      };

    const response = await getAllFavoritesAndFolders();

    if (response.isSuccessful) {
      set({
        favorites: response.response.payload?.favoriteMaterials ?? [],
        favoriteFolders: response.response.payload?.favoriteFolders ?? [],
        globalFavoriteFolders:
          response.response.payload?.globalFavoriteFolders ?? [],
        favoritesInitiated: true,
      });
    }
    return {
      favorites: get().favorites,
      favoriteFolders: get().favoriteFolders,
      globalFavoriteFolders: get().globalFavoriteFolders,
    };
  },
  addFavorite: async (
    materialId,
    materialType,
    materialLabel,
    parentFolders = [DEFAULTFOLDERNAME],
  ) => {
    if (materialId && materialType && materialLabel) {
      trackEvent(
        mapMaterialTypeToCategory(materialType),
        'AddFavorite',
        materialLabel,
      );
    } else {
      logError({
        message: 'Could not track Add Favorite event. Missing data.',
        data: {
          materialId,
          materialType,
          materialLabel,
        },
      });
    }

    const response = await addFavorite(materialId, parentFolders);

    if (response.isSuccessful) {
      set((state) => ({
        favorites: [
          ...state.favorites,
          {
            materialId,
            parentFolderIds: [DEFAULTFOLDERNAME],
            isGlobalFavoriteMaterial: false,
          },
        ],
      }));
    }
  },
  removeFavorite: async (materialId) => {
    const response = await removeFavorite(materialId);

    if (response.isSuccessful) {
      set(({ favorites }) => ({
        favorites: favorites.filter(
          (favorite) => favorite.materialId !== materialId,
        ),
      }));
      await get().getFavorites(true);
    }
  },
  toogleFavorite: async (materialId, materialType, materialLabel) => {
    if (get().isFavorite(materialId)) {
      await get().removeFavorite(materialId);
    } else {
      await get().addFavorite(materialId, materialType, materialLabel);
    }
  },
  isFavorite: (materialId) => {
    return (
      get().favorites.find(
        (favorite) =>
          favorite.materialId === materialId &&
          favorite.parentFolderIds.length > 0,
      ) != null
    );
  },
  changeFoldersOfFavorite: async (materialId, folderIds) => {
    const globalFolderIds = get().globalFavoriteFolders.map(
      (folder) => folder.id,
    );
    const response = await changeFoldersOfFavorite(
      materialId,
      folderIds.filter((folderId) => !globalFolderIds.includes(folderId)),
    );

    if (response.isSuccessful) {
      const favoriteIndex = get().favorites.findIndex(
        (favorite) => favorite.materialId === materialId,
      );
      const updatedFavorite = get().favorites[favoriteIndex];
      updatedFavorite.parentFolderIds = folderIds;

      const updatedFavorites = [...get().favorites];
      updatedFavorites.splice(favoriteIndex, 1, updatedFavorite);
      set({ favorites: updatedFavorites });
    }
  },
  removeFavoriteFromFolder: async (favoriteId, folderId) => {
    const favorite = get().favorites.find(
      (favorite) => favorite.materialId === favoriteId,
    );

    if (favorite == null) return;

    await get().changeFoldersOfFavorite(
      favoriteId,
      favorite?.parentFolderIds.filter((folder) => folder !== folderId),
    );
  },
  addFavoriteToFolder: async (favoriteId, folderId) => {
    const favorite = get().favorites.find(
      (favorite) => favorite.materialId === favoriteId,
    );

    if (favorite == null) return;

    await get().changeFoldersOfFavorite(favoriteId, [
      ...favorite.parentFolderIds,
      folderId,
    ]);
  },
  addFolder: async (name) => {
    const folderName =
      name ??
      i18n.t('favorites.defaultFolderName', {
        letter: get().favoriteFolders.length + 1,
      });

    const response = await addFolder(folderName);

    if (response.isSuccessful && response.response.payload) {
      set({
        favoriteFolders: [...get().favoriteFolders, response.response.payload],
      });
      return response.response.payload.id;
    }

    return null;
  },
  removeFolder: async (id: string) => {
    const response = await removeFolder(id);

    if (response.isSuccessful) {
      await get().getFavorites(true);

      if (get().activeFolderId === id) {
        set({ activeFolderId: null });
      }
    }
  },
  renameFolder: async (id: string, newName: string) => {
    const response = await renameFolder(id, newName);

    if (response.isSuccessful) {
      const folders = [...get().favoriteFolders];
      const folderIndex = folders.findIndex((folder) => folder.id === id);
      folders[folderIndex] = {
        id,
        name: newName,
        isGlobalFavoriteFolder: false,
        hasThumbnail: false,
      };
      set({ favoriteFolders: folders });
    }
  },
  duplicateFolder: async (id, nameSuffix) => {
    const folder =
      get().favoriteFolders.find((f) => f.id === id) ??
      get().globalFavoriteFolders.find((f) => f.id === id);
    const isAdminFolder = folder?.isGlobalFavoriteFolder;

    if (folder == null) return;
    const newFolderId = await get().addFolder(`${folder.name} ${nameSuffix}`);

    if (newFolderId == null) return;

    get().favorites.forEach(async (favorite) => {
      const isAdminDuplicate =
        isAdminFolder && favorite.isGlobalFavoriteMaterial;

      if (isAdminDuplicate) {
        if (favorite.parentFolderIds.length === 0) {
          await get().addFavorite(favorite.materialId, undefined, undefined, [
            newFolderId,
          ]);
        } else {
          const newFolders = [...favorite.parentFolderIds, newFolderId];
          await get().changeFoldersOfFavorite(favorite.materialId, newFolders);
        }

        return favorite;
      }

      if (!favorite.parentFolderIds.includes(id)) return favorite;
      const newFolders = [...favorite.parentFolderIds, newFolderId];

      await get().changeFoldersOfFavorite(favorite.materialId, newFolders);

      return {
        materialId: favorite.materialId,
        parentFolderIds: newFolders,
      };
    });

    await get().getFavorites(true);
  },
  setActiveFolderId: (id: string | null) => {
    set({ activeFolderId: id });
  },
}));

export const useFavoriteStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
