import { Grid, HStack, Stack } from '@chakra-ui/react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { FC, RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { CollectionFilter } from '$/components/core/Collection/CollectionFilter';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { DataSortingRow } from '$/components/core/Collection/MaterialPresentation/DataSortingRow';
import { SearchDropdown } from '$/components/core/Collection/MaterialSearch/SearchDropdown';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { useCustomMouseSensor } from '$/components/core/DragAndDrop/customMouseSensor';
import { AuthActionCard } from '$/components/core/Projects/components/Cards/AuthActionCard';
import { TooltipHeader } from '$/pages/DashboardPages/components/TooltipHeader';
import { FavoriteFolders } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteFolders';
import { FavoriteGroup } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteGroup';
import { GlobalFavoriteFolders } from '$/pages/DashboardPages/pages/Favorite/components/GlobalFavoriteFolders';
import { useGroupedFavorites } from '$/pages/DashboardPages/pages/Favorite/hooks/useGroupedFavorites';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
}

export const FavoriteDashboardContent: FC<Props> = ({ sectionRef }) => {
  const { t } = useTranslation();
  const [isCopyMode, setIsCopyMode] = useState(false);
  const setActiveMaterial = useMaterialDetailStore.useSetActive();
  const changeFoldersOfFavorite = useFavoriteStore.useChangeFoldersOfFavorite();
  const favorites = useFavoriteStore.useFavorites();
  const query = useMaterialSearchStore.useQuery();
  const { hasPermission } = useAuthorization();

  const {
    colorFavorites,
    floorFavorites,
    wallFavorites,
    facadeFavorites,
    isLoading,
  } = useGroupedFavorites(DEFAULTFOLDERNAME, undefined, false, query);

  const onDragEnd = async (result: DropResult) => {
    const { draggableId, destination } = result;
    if (destination == null) {
      return;
    }

    const favoriteId = draggableId.split('&&')[0];
    const favorite = favorites.find(
      (favorite) => favorite.materialId === favoriteId,
    );

    if (
      favorite == null ||
      favorite.parentFolderIds.includes(destination.droppableId)
    )
      return;

    const newFavorites = isCopyMode
      ? favorite.parentFolderIds
      : favorite.parentFolderIds.filter((id) => id !== DEFAULTFOLDERNAME);

    newFavorites.push(destination.droppableId);

    await changeFoldersOfFavorite(favoriteId, newFavorites);
  };

  const favoriteIds = favorites.map((favorite) => favorite.materialId);

  return (
    <>
      <HStack
        align='flex-start'
        justify='space-between'
        flexWrap={{ base: 'wrap', mobile: 'nowrap' }}
        gap={{ base: '3', mobile: '4' }}
      >
        <TooltipHeader header={t('favorites.pageHeader')} />
        {hasPermission('Favorites_Open') && (
          <SearchDropdown
            maxW='275px'
            w='100%'
            searchLabel={t('dashboard.collection.searchColors')}
            onResultClick={(material) => setActiveMaterial(material)}
            idFilter={favoriteIds}
          />
        )}
      </HStack>

      {hasPermission('Favorites_Open') && (
        <Stack mt={{ base: '5', sm: '0' }}>
          <CollectionFilter
            sectionRef={sectionRef}
            type='all'
            hideAmounts
            amount={null}
          />
        </Stack>
      )}

      <DataSortingRow amount='?' hideCount scrollRefs={[sectionRef]} />

      <Stack gap='6' pt={{ base: '0', sm: '6' }}>
        <DragDropContext
          onDragEnd={onDragEnd}
          enableDefaultSensors={false}
          sensors={[useCustomMouseSensor]}
        >
          {!hasPermission('Favorites_Open') && (
            <Grid gap='6' templateColumns={materialTemplateColumn()}>
              <AuthActionCard type='loginFavorite' />
            </Grid>
          )}
          <GlobalFavoriteFolders />
          <FavoriteFolders />
          <FavoriteGroup
            headline={t('dashboard.collection.search.colorTones')}
            favorites={colorFavorites}
            isLoading={isLoading}
            materialType='uni'
            setMoveMode={setIsCopyMode}
          />
          <FavoriteGroup
            headline={t('dashboard.collection.search.wallCoverings')}
            favorites={wallFavorites}
            isLoading={isLoading}
            materialType='wall'
            setMoveMode={setIsCopyMode}
          />
          <FavoriteGroup
            headline={t('dashboard.collection.search.floorCoverings')}
            favorites={floorFavorites}
            isLoading={isLoading}
            materialType='floor'
            setMoveMode={setIsCopyMode}
          />
          <FavoriteGroup
            headline={t('dashboard.collection.search.facades')}
            favorites={facadeFavorites}
            isLoading={isLoading}
            materialType='facade'
            setMoveMode={setIsCopyMode}
          />
        </DragDropContext>
      </Stack>
    </>
  );
};
